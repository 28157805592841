<template id="users">
  <b-container fluid>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting">
      <h5 class="mt-2">
        Users ({{ total }})
        <b-button variant="primary" class="ml-2" size="sm" @click="newUser"
          >New</b-button
        >
        <b-button v-b-toggle.collapse-1 variant="primary" class="ml-2" size="sm"
          >Filter</b-button
        >
      </h5>
      <b-collapse id="collapse-1" class="mt-2">
        <b-row class="mb-1">
          <b-col sm="2">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filters.search_name"
                placeholder="Name"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="filtraUtenti"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="4">
            <multiselect
              v-model="filters.gruppi"
              :options="gruppi"
              :multiple="true"
              track-by="id"
              label="nome"
              placeholder="Select groups"
            ></multiselect>
          </b-col>
        </b-row>
      </b-collapse>
      <v-infinite-scroll
        :loading="loading"
        @bottom="nextPage"
        :offset="20"
        style="max-height: 80vh; overflow-y: scroll; overflow-x: hidden"
        class="row"
      >
        <b-col
          sm="6"
          md="6"
          lg="4"
          v-for="(utente, index) in utenti"
          :key="index"
          class="p-0 m-0"
        >
          <div @click="editUser(utente)" style="cursor: pointer">
            <user-card :user="utente" />
          </div>
        </b-col>
        <div v-if="loading" align="center" style="width: 100%">
          <img :src="loadingImg" style="width: 150px" />
        </div>
      </v-infinite-scroll>
    </div>
    <b-modal
      ref="userModal"
      @ok="saveUser"
      :title="form.id ? 'Edit user' : 'New user'"
      ok-title="Save"
      cancel-title="Close"
    >
      <b-form-group label="Last name" horizontal>
        <b-form-input
          type="text"
          placeholder="Last name"
          v-model="form.cognome"
        />
      </b-form-group>
      <b-form-group label="First name" horizontal>
        <b-form-input
          type="text"
          placeholder="First name"
          v-model="form.nome"
        />
      </b-form-group>
      <b-form-group label="Nickname" horizontal>
        <b-form-input
          type="text"
          placeholder="Nickname"
          v-model="form.nickname"
        />
      </b-form-group>
      <b-form-group label="Username" horizontal>
        <b-form-input
          type="text"
          v-model="form.username"
          :plaintext="form.id ? true : false"
        />
      </b-form-group>
      <b-form-group label="New password" horizontal>
        <b-form-input type="password" v-model="form.new_password" />
      </b-form-group>
      <b-form-group label="Repeat password" horizontal>
        <b-form-input type="password" v-model="form.ripeti_password" />
      </b-form-group>
      <b-form-group v-if="form.id" label="Send new password" horizontal>
        <b-form-checkbox v-model="form.notifica" value="1" />
      </b-form-group>
      <b-form-group label="Email" horizontal>
        <b-form-input type="email" placeholder="Email" v-model="form.email" />
      </b-form-group>
      <b-form-group label="Active" horizontal>
        <b-form-checkbox v-model="form.attivo" value="1" />
      </b-form-group>
      <b-form-group v-if="form.id" label="Adress" horizontal>
        <b-form-input type="text" v-model="form.indirizzo" plaintext />
      </b-form-group>
      <b-form-group label="Groups" horizontal>
        <multiselect
          v-model="form.gruppi"
          :multiple="true"
          :options="gruppi"
          placeholder="Select group"
          label="nome"
          track-by="id"
        />
      </b-form-group>
      <b-form-group label="Permits" horizontal>
        <multiselect
          v-model="form.permessi"
          :multiple="true"
          :options="permessi"
          placeholder="Select permit"
          label="nome"
          track-by="id"
        />
      </b-form-group>
    </b-modal>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import UserCard from "@/components/UserCard.vue";
import loadingImg from "@/assets/images/loading.gif";
import InfiniteScroll from "v-infinite-scroll";
import Multiselect from "vue-multiselect";
export default {
  components: {
    UserCard,
    Multiselect,
    "v-infinite-scroll": InfiniteScroll,
  },

  data: function () {
    return {
      waiting: true,
      loading: false,
      gruppi: [],
      utenti: [],
      permessi: [],
      total: 0,
      page: 1,
      filters: {
        search_name: "",
        gruppi: [],
      },
      form: {
        id: null,
        nome: "",
        cognome: "",
        nickname: "",
        username: "",
        new_password: "",
        ripeti_password: "",
        notifica: false,
        attivo: false,
        email: "",
        indirizzo: "",
        gruppi: [],
        permessi: [],
      },
      loadingImg,
    };
  },

  created: function () {
    this.getUsers();
    this.getGroups();
    this.getPermessi();
  },

  methods: {
    getUsers() {
      this.waiting = true;
      this.$http.get("/admin/utenti").then((response) => {
        this.utenti = response.data.data;
        this.total = response.data.total;
        this.waiting = false;
      });
    },

    getGroups() {
      this.$http.get("/admin/gruppi").then((response) => {
        this.gruppi = response.data;
      });
    },

    getPermessi() {
      this.$http.get("/admin/permessi").then((response) => {
        this.permessi = response.data;
      });
    },

    nextPage() {
      if (this.utenti.length < this.total) {
        this.page++;
        this.loading = true;
        this.$http
          .get("/admin/utenti?page=" + this.page, {
            params: this.filters,
          })
          .then((response) => {
            this.utenti = this.utenti.concat(response.data.data);
            this.loading = false;
          });
      }
    },

    filtraUtenti() {
      this.loading = true;
      this.utenti = [];
      this.page = 1;
      this.$http
        .get("/admin/utenti", { params: this.filters })
        .then((response) => {
          this.utenti = response.data.data;
          this.total = response.data.total;
          this.loading = false;
        });
    },

    newUser() {
      this.form.id = null;
      this.form.cognome = "";
      this.form.nome = "";
      this.form.nickname = "";
      this.form.username = "";
      this.form.new_password = "";
      this.form.ripeti_password = "";
      this.form.notifica = false;
      this.form.attivo = false;
      this.form.email = "";
      this.form.indirizzo = "";
      this.form.gruppi = [];
      this.form.permessi = [];

      this.$refs.userModal.show();
    },

    editUser(user) {
      this.form.id = user.id;
      this.form.cognome = user.cognome;
      this.form.nome = user.nome;
      this.form.nickname = user.nickname;
      this.form.username = user.username;
      this.form.new_password = "";
      this.form.ripeti_password = "";
      this.form.notifica = false;
      this.form.attivo = user.attivo;
      this.form.email = user.email;
      this.form.indirizzo = user.indirizzo;
      this.form.gruppi = user.gruppi;
      this.form.permessi = user.permessi;

      this.$refs.userModal.show();
    },

    saveUser() {
      var url = "/admin/add/user";
      if (this.form.id) {
        url = "/admin/update/user/" + this.form.id;
      }
      this.$http
        .post(url, this.form)
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          this.getUsers();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.$store.commit("msgPush", {
              msg: error.response.data.error,
              status: 0,
            });
          }
        });
    },
  },

  watch: {
    "filters.gruppi": function () {
      this.filtraUtenti();
    },
  },

  computed: {},
  filters: {},
};
</script>
