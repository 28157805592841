<template id="usercard">
  <b-card class="m-1" :bg-variant="!user.attivo ? 'secondary' : ''">
    <b-row>
      <b-col class="p-0" align-self="center" cols="12">
        <b-row align-v="center">
          <b-col>
            <avatar :avatar="user.avatar_url" :id="'user_' + user.id" />
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="12" style="font-size: 1rem; font-weight: bold">
                <div
                  :title="user.nome + ' ' + user.cognome"
                  style="
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ user.nome }} {{ user.cognome }}
                </div>
              </b-col>
              <b-col
                cols="12"
                style="font-size: 0.8rem; min-height: 18px"
                title="Nickname"
              >
                {{ user.nickname }}
              </b-col>
              <b-col
                cols="12"
                style="font-size: 0.8rem; min-height: 18px"
                title="Username"
              >
                {{ user.username }}
              </b-col>
              <b-col
                cols="12"
                style="font-size: 0.8rem; min-height: 18px"
                title="Email"
              >
                {{ user.email }}
              </b-col>
              <b-col
                cols="12"
                style="font-size: 0.8rem; min-height: 18px"
                title="Address"
              >
                {{ user.indirizzo }}
              </b-col>
              <b-col
                cols="12"
                style="font-size: 0.8rem; min-height: 18px"
                title="Address"
              >
                <span v-for="gruppo in user.gruppi" :key="gruppo.id">
                  <b-badge variant="primary" class="mr-2">{{
                    gruppo.nome
                  }}</b-badge>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import Avatar from "@/components/Avatar.vue";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {};
  },

  components: {
    Avatar,
  },

  created: function () {},

  computed: {
    auth: function () {
      return Auth;
    },
  },

  methods: {},

  filters: {
    year: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YYYY");
      }
      return "";
    },
    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
